import React from 'react'
import Link from 'gatsby-link'

import Header from '../components/header'
import '../components/layout.css'

const NotFoundPage = () => (
  <div>
  <Header />
  <div className="notFound">
    <div className="big404">404</div>
      <div className="oops"><p>Go <Link to="/"> home</Link>! There's nothing here yet, I'm afraid.</p></div>
  </div>
  </div>
)

export default NotFoundPage